import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Logo, WomenPicture, MenPicture } from './icons';

const query = graphql`
query QRQuery {
    de: file(relativePath: {eq: "Menukarte_DE.pdf"}) {
        publicURL
      }
      en: file(relativePath: {eq: "Menukarte_ENG.pdf"}) {
          publicURL
      }
      fr: file(relativePath: {eq: "Menukarte_FR.pdf"}) {
          publicURL
      }
      nl: file(relativePath: {eq: "Menukarte_NL.pdf"}) {
          publicURL
      }
  }
`;

const Menu = () => {

    const data = useStaticQuery(query);

    return (
        <div className="menu">
            <div className="menu__content">
                <div className="menu__logo">
                    <Logo />
                </div>
                <div className="menu__title">
                    <div className="menu__icons">
                        <div className="menu__icon menu__icon--women">
                            <WomenPicture />
                        </div>
                        <div className="menu__icon menu__icon--men">
                            <MenPicture />
                        </div>
                    </div>
                    <h3>
                        Menü
                    </h3>
                </div>
                <div className="menu__cta">
                    <a className="menu__cta--btn" href={data.de.publicURL} target="_blank" rel="noreferrer noopener">
                        Deutsch
                    </a>
                    <a className="menu__cta--btn" href={data.fr.publicURL} target="_blank" rel="noreferrer noopener">
                        Français
                    </a>
                    <a className="menu__cta--btn" href={data.en.publicURL} target="_blank" rel="noreferrer noopener">
                        English
                    </a>
                    {/* <a className="menu__cta--btn" href={data.nl.publicURL} target="_blank" rel="noreferrer noopener">
                        Nederlands
                    </a> */}
                </div>
            </div>
        </div>
    );
};

export default Menu;