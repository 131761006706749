import React from 'react';
import { Link }from 'gatsby';
 //
import { FooterLogo, InstagramIcon, FacebookIcon, Ostbelgien } from './icons';

const Footer = props => { 
    

    const { contact, hours, social, impressum } = props.data;

    return (
        <footer className="footer">
            <div className="container">
                <div className="footer__content">
                    <div className="logo">
                        <FooterLogo />
                    </div>
                    <div className="working-hours">
                        <h4 className="title">
                            Öffnungszeiten
                        </h4>
                        <div className="working-hours__container">
                            <span className="working-hours__container--title">
                                Mo-Fr
                            </span>
                            <div className="working-hours__container--hours" dangerouslySetInnerHTML={{ __html: hours.mo_fr }} />
                        </div>
                    </div>
                    <div className="contact">
                        <ul className="social">
                            <li>
                                <a href={social.instagram} target="__blank" rel="noreferrer noopener">
                                    <InstagramIcon />
                                </a>
                            </li>
                            <li>
                                <a href={social.facebook} target="__blank" rel="noreferrer noopener">
                                    <FacebookIcon />
                                </a>
                            </li>
                        </ul>
                        <div className="phone">
                            <a href={`tel:${contact.phone}`}>
                                {contact.phone}
                            </a>
                        </div>
                        <div className="email">
                            <a href={`mailto:${contact.email}`}>
                                {contact.email}
                            </a>
                        </div>
                        <div className='ostbelgien-icon'>
                        <Ostbelgien/>
                        </div>
                    </div>
                </div>
                <div className="footer__menu">
                    <ul>
                        <li>
                            <Link to='/impressum'>
                                Impressum
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
