import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';

import { Logo, MobileMenu, MobileClose, InstagramIcon, FacebookIcon } from './icons';

const Header = ({ nav, data }) => {

    const [ fixed, setFixed ] = useState(false);

    const navigation = useRef(null);

    const [ mobileIcon, setMobileIcon ] = useState(false);
    const [ mobileMenu, setMobileMenu ] = useState(false);

    useEffect(() => {
        document.body.style.overflow = mobileMenu ? 'hidden' : 'visible';
    }, [mobileMenu]);

    const checkSize = () => {
        const width = window.innerWidth || document.body.clientWidth;

        setMobileIcon(width < 992 ? true : false);
    }

    useEffect(() => {
        checkSize();

        window.onscroll = () => {
            
            const position = window.pageYOffset;
            const navHeight = navigation.current.offsetHeight;

            setFixed(position > navHeight ? true : false);
        }

        window.onresize = checkSize;
    }, []);

    return (
        <nav className={`navigation ${mobileIcon || nav === 'fixed' ? 'fixed' : (fixed ? 'fixed' : '')}`} ref={navigation}>
            {mobileMenu && (
                <div className="mobile-menu">
                    <ul className="mobile-menu-list">
                        <li className="box">
                            <Link to='/gerichte'>
                                Vorbestellen
                            </Link>
                        </li>
                        <li className="box">
                            <Link to='/uber-uns'>
                                über uns
                            </Link>
                        </li>
                        <li className="box">
                            <div className="icons">
                                <div className="icon">
                                    <a href={data.social.instagram} target="_blank">
                                        <InstagramIcon />
                                    </a>
                                </div>
                                <div className="icon">
                                    <a href={data.social.facebook} target="_blank">
                                        <FacebookIcon />
                                    </a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            )}
            {mobileIcon && (
                <div className="mobile-menu-trigger" onClick={() => setMobileMenu(!mobileMenu)}>
                    {mobileMenu ? <MobileClose /> : <MobileMenu />}
                </div>
            )}
            <div className="container navigation-container">
                <div className="navigation__wrapper">
                    <div className="logo">
                        <Link to='/'>
                            <Logo />
                        </Link>
                    </div>
                    <ul className="navigation__wrapper--menu">
                        <li className="link">
                            <Link to='/gerichte'>
                                Vorbestellen
                            </Link>
                        </li>
                        <li className="link">
                            <Link to='/uber-uns'>
                                über uns
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Header;