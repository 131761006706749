import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Navigation from './navigation';
import Menu from './menu';
import Footer from './footer';

import '../sass/style.scss';

const query = graphql`
    query globalData {
        allFile(filter: {relativePath: {eq: "info.md"}}) {
            nodes {
            childMarkdownRemark {
                frontmatter {
                contact {
                    email
                    phone
                }
                hours {
                    mo_fr
                    sa
                }
                social {
                    facebook
                    instagram
                }
                impressum {
                    publicURL
                }
                }
            }
            }
        }
    }
`;

const Layout = ({ children, nav, footer = true, withMenu = false }) => {

    const getData = useStaticQuery(query);

    const data = getData.allFile.nodes[0].childMarkdownRemark.frontmatter;

    return (
        <div className="content">
            {withMenu && <Menu />}
            <Navigation nav={nav} data={data} />
                <main className="main">
                    {children}
                </main>
            {footer && <Footer data={data} />}
        </div>
    )
}

export default Layout;